import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { ToastrService } from 'ngx-toastr';

function promptUser(event): boolean {
    return true;
}

// #docregion sw-activate
@Injectable()
export class PromptUpdateService {

    constructor(updates: SwUpdate, toastr: ToastrService) {
        updates.available.subscribe(event => {
            toastr.info('Доступна новая версия. Обновите страницу.', '', {disableTimeOut: true});
        });
    }
}
// #enddocregion sw-activate
