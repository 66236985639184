import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { EmployeeModalModule } from 'portal/modules/employee-modal/employee-modal.module';
import { MyImageCropperModule } from 'portal/modules/image-cropper/image-cropper.module';
import { PortalNavbarComponent } from 'portal/modules/navigation/navbar/portal-navbar.component';
import { UserPhotoModule } from 'portal/modules/user-photo/user-photo.module';
import { ModalModule } from 'shared/modules/modal/modal.module';
import { NavigationModule } from 'shared/modules/navigation/navigation.module';
import { SidebarMenuItemModule } from 'shared/modules/navigation/sidebar-menu-item/sidebar-menu-item.module';
import { NavigationService } from './navigation.service';
import { SidebarComponent } from './sidebar/sidebar.component';
import {TooltipModule} from 'ngx-bootstrap/tooltip';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NavigationModule,
    SidebarMenuItemModule,
    BsDropdownModule,
    UserPhotoModule,
    EmployeeModalModule,
    ModalModule,
    MyImageCropperModule,
    TooltipModule,
  ],
  providers: [
    NavigationService,
  ],
  declarations: [SidebarComponent, PortalNavbarComponent],
  exports: [SidebarComponent, PortalNavbarComponent],
})
export class PortalNavigationModule {}
