import { deepCopy } from '@angular-devkit/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AfterContentInit, ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { EnumsService } from 'portal/services/enums.service';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, forkJoin, Observable, Subject, Subscription } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import { ApiResponse } from 'shared/interfaces/response';
import { format } from 'date-fns';
import { NewItemModel } from 'shared/modules/forms/history-input/history-input.component';

type MyDataStructure = {
    effective_since: string;
    agency_id: number;
    agency_name: string;
};
const initialMyDataStructure: MyDataStructure[] = [];

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'agency-history-input',
    template: `            
        <div class="card mt-1 mb-1 position-relative" style="min-height: 150px">
            <div class="card-body">
                <table class="table table-striped mb-0">
                    <thead>
                    <tr>
                        <th>
                            Агентство
                        </th>
                        <th>
                            Актуально с
                        </th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let item of initialValue; index as i">
                        <td>
                            {{ item.agency_name }}
                        </td>
                        <td>
                            {{ item.effective_since | date:'shortDate' }}
                        </td>
                        <td>
                            <button type="button" class="btn btn-danger" (click)="deleteItem(i)"><i class="fa fa-trash"></i></button>
                        </td>
                    </tr>
                    </tbody>
                </table>

            </div>
            <div class="card-footer">
                <form ngNoForm (submit)="false">
                    <div class="container">
                        <div class="row">
                            <div class="col-8 pl-0">
                                <formly-form
                                             [model]="newItem"
                                             [fields]="fields">
                                </formly-form>
                            </div>
                            <div class="col-4 pr-0">
                                <button type="button" class="btn btn-secondary w-100 mb-3" (click)="addItem(newItem)">
                                    Добавить
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    `,
})
export class AgencyHistoryInputComponent extends FieldType implements AfterContentInit {
    fields: FormlyFieldConfig[] = [
        {
            key: 'agency_id',
            type: 'select',
            templateOptions: {
                label: 'Агентство',
                options: this.enums.getAgencyList(),
            },
        },
        {
            key: 'effective_since',
            type: 'date',
            templateOptions: {
                label: 'Актуально с',
                pickerMode: 'calendar',
            },
        },
    ];
    agencies = [];
    newItem: MyDataStructure = {effective_since: '', agency_id: null, agency_name: ''};
    formControl: FormControl;
    internalControl = new FormArray([]);
    initialValue = initialMyDataStructure;

    sub = new Subscription();

    constructor(public enums: EnumsService, private fb: FormBuilder) {
        super();
    }

    ngAfterContentInit(): void {
        if (this.formControl.value) {
            this.initialValue = this.formControl.value;
        }

        const valueChangesSub = this.internalControl.valueChanges
            .subscribe(it => {
                this.formControl.patchValue(it);
            });

        this.sub.add(valueChangesSub);

        this.enums.getAgencyList().subscribe(data => this.agencies = data);

        this.initForm();
    }

    initForm() {
        while (this.internalControl.length > 0) {
            this.internalControl.removeAt(0);
        }
        this.initialValue.forEach(model => this.internalControl.push(this.getNewControl(model)));
        // this.internalControl.patchValue(this.initialValue);
    }

    deleteItem(index: number) {
        this.initialValue.splice(index, 1);
        this.internalControl.removeAt(index);
    }

    addItem(model: MyDataStructure) {
        model = {...model};
        if (model.effective_since) {
            model.effective_since = format(model.effective_since, 'YYYY-MM-DD');
        }
        // @ts-ignore
        model.agency_name = this.agencies.filter(item => item.value === model.agency_id)[0].label;
        this.initialValue.push(model);
        this.internalControl.push(this.getNewControl(model));
    }

    getNewControl(model: MyDataStructure) {
        return this.fb.group({ ...model });
    }
}
