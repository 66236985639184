<div class="container-fluid">
    <div class="card mt-3">
        <div class="card-body">
            <div class="panel bg-light p-1">
                <div class="text-editor-panel-of-button" [class.panel-disabled]="headingHaveFocus$ | async">
                    <div *ngIf="!idCurrentImage && !idCurrentTable" class="d-flex align-items-center flex-wrap">
                        <ng-select class="form-control ml-2"
                                   style="width: 18rem"
                                   [searchable]="false"
                                   [clearable]="false"
                                   [bindValue]="'value'"
                                   [disabled]="headingHaveFocus$ | async"
                                   [(ngModel)]="textEditorService.selectionProperties.blockSelected"
                                   (ngModelChange)="execute($event)"
                                   [items]="blockOptions">
                            <ng-template ng-option-tmp let-item="item">
                                <span class="{{ item.tag }}">{{ item.label }}</span>
                            </ng-template>
                        </ng-select>
                        <div class="editor-control-buttons-group">
                            <button
                                    [tooltip]="'Разделитель'"
                                    class="btn btn-light"
                                    (click)="addBreak()"
                            >
                                <i class="fa fa-code"></i>
                            </button>
                        </div>
                        <ng-container *ngIf="textEditorService.contentState$ | async as contentState">
                            <div class="editor-control-buttons-group" *ngFor="let btnGroup of buttonsMenu">
                                <button type="button" *ngFor="let btn of btnGroup"
                                        [class.bg-primary]="contentState[btn.command]"
                                        [class.text-white]="contentState[btn.command]"
                                        [tooltip]="btn.tooltip"
                                        class="btn btn-light"
                                        (click)="execute(btn.command)"
                                >
                                    <i class="{{ btn.icon }}"></i>
                                </button>
                            </div>
                        </ng-container>
                        <div class="editor-control-buttons-group">
                            <button type="button" (click)="TextEditorService.activeInstance.executeCommand('removeFormat')"
                                    class="keep-enabled"
                                    tooltip="Убрать форматирование">
                                <i class="fa fa-times"></i>
                            </button>
                        </div>
                        <div class="editor-control-buttons-group" #colorPickersPanel>
                            <div class="position-relative">
                                <button type="button" class="keep-enabled"
                                        tooltip="Цвет текста"
                                        (click)="openedPicker = 'foreground'">
                                    <i class="fa fa-palette"></i>
                                </button>
                                <div class="picker-container"
                                     [class.d-none]="openedPicker !== 'foreground'">
                                    <color-twitter *ngIf="TextEditorService.activeInstance?.selectionProperties
                                        .selectionForegroundColor"
                                                   triangle="top-right"
                                                   [colors]="colors"
                                                   [color]="TextEditorService.activeInstance.selectionProperties
                                        .selectionForegroundColor"
                                                   (onChangeComplete)="TextEditorService.activeInstance
                                        .insertColor($event.color.hex, 'textColor')"
                                    ></color-twitter>
                                </div>
                            </div>
                            <div class="position-relative">
                                <button type="button" class="keep-enabled"
                                        tooltip="Цвет выделения текста"
                                        (click)="openedPicker = 'background'">
                                    <i class="fa fa-audio-description"></i>
                                </button>
                                <div class="picker-container"
                                     [class.d-none]="openedPicker !== 'background'">
                                    <color-twitter *ngIf="TextEditorService.activeInstance?.selectionProperties
                                        .selectionBackgroundColor"
                                                   triangle="top-right"
                                                   [colors]="colors"
                                                   [color]="TextEditorService.activeInstance.selectionProperties
                                        .selectionBackgroundColor"
                                                   (onChangeComplete)="TextEditorService.activeInstance
                                        .insertColor($event.color.hex, 'backgroundColor')"
                                    ></color-twitter>
                                </div>
                            </div>
                        </div>
                        <div class="editor-control-buttons-group">
                            <button type="button" (click)="addLink()"
                                    [class.disabled]="!textEditorService.selectionProperties.rangeSelected"
                                    tooltip="Вставить ссылку">
                                <i class="fa fa-link"></i>
                            </button>
                            <button type="button" (click)="execute('unlink')"
                                    [class.disabled]="!textEditorService.selectionProperties.linkSelected"
                                    tooltip="Редактировать ссылку">
                                <i class="fa fa-unlink"></i>
                            </button>
                        </div>
                        <div class="editor-control-buttons-group">
                            <button type="button" [popover]="grid" outsideClick="true" container="body" placement="bottom"
                                    tooltip="Создать разметку (сетку)">
                                <i class="fas fa-border-none"></i>
                            </button>
                            <ng-template #grid>
                                <div>
                                    Сетка
                                    <span *ngIf="buttonsOfBlocksX && buttonsOfBlocksY">
                        {{buttonsOfBlocksX / 20 | mathCeil }}x{{buttonsOfBlocksY / 20 | mathCeil }}
                    </span>
                                </div>
                                <div id="buttonsOfGrid">
                                    <div class="d-flex flex-column">
                                        <div *ngFor="let row of blocksStructure">
                                            <div class="d-flex">
                                                <div *ngFor="let col of blocksStructure">
                                                    <div (mousemove)="changeStyleCellOfButtonOfBlocks($event,'buttonsOfGrid')"
                                                         (click)="createGrid($event)"
                                                         [ngClass]="{'active-cell': (buttonsOfBlocksX > col.x && buttonsOfBlocksY > row.y)}"
                                                         class="cell"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                            <button type="button" [popover]="table" outsideClick="true" container="body" placement="bottom"
                                    tooltip="Создать таблицу">
                                <i class="fas fa-table"></i>
                            </button>
                            <ng-template #table>
                                <div>
                                    Таблица
                                    <span *ngIf="buttonsOfBlocksX && buttonsOfBlocksY">
                        {{buttonsOfBlocksX / 20 | mathCeil }}x{{buttonsOfBlocksY / 20 | mathCeil }}
                    </span>
                                </div>
                                <div id="buttonsOfTable">
                                    <div class="d-flex flex-column">
                                        <div *ngFor="let row of blocksStructure">
                                            <div class="d-flex">
                                                <div *ngFor="let col of blocksStructure">
                                                    <div (mousemove)="changeStyleCellOfButtonOfBlocks($event, 'buttonsOfTable')"
                                                         (click)="createTable($event)"
                                                         [ngClass]="{'active-cell': (buttonsOfBlocksX > col.x && buttonsOfBlocksY > row.y)}"
                                                         class="cell border border-primary"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                        <div class="editor-control-buttons-group">
                            <button type="button" (click)="addImage()" tooltip="Вставить изображение">
                                <i class="fa fa-image"></i>
                            </button>
                        </div>
                        <div class="editor-control-buttons-group">
                            <button type="button" (click)="addCallToAction()" tooltip="Создать 'call to action'">
                                <i class="fas fa-bullhorn"></i>
                            </button>
                            <button type="button" (click)="addIFrame()" tooltip="Создать iFrame">
                                <i class="fas fa-external-link-alt"></i>
                            </button>
                            <button type="button" (click)="addFile()" tooltip="Прикрепить файл">
                                <i class="fa fa-upload"></i>
                            </button>
                        </div>
                        <div class="editor-control-buttons-group">
                            <button type="button" (click)="save()" tooltip="Сохранить">
                                <i class="fa fa-save"></i>
                            </button>
                        </div>
                    </div>
                    <div *ngIf="!!idCurrentImage" class="editor-control-buttons-group">
                        <button type="button" (click)="editImage('square')" [tooltip]="'Оригинальное изображение'">
                            <i class="fas fa-square-full"></i>
                        </button>
                        <button type="button" (click)="editImage('circle')" [tooltip]="'Круглое изображение'">
                            <i class="fas fa-circle"></i>
                        </button>
                        <button type="button" (click)="editImage('border-square')" [tooltip]="'Оригинальное изображение в рамке'">
                            <i class="far fa-square"></i>
                        </button>
                        <button type="button" (click)="editImage('border-circle')" [tooltip]="'Круглое изображение в рамке'">
                            <i class="far fa-circle"></i>
                        </button>
                    </div>
                    <div *ngIf="!!idCurrentTable" class="editor-control-buttons-group">
                        <button type="button" (click)="editTable('standart')">
                            Стандартная
                        </button>
                        <button type="button" (click)="editTable('striped')">
                            "Полосатая"
                        </button>
                        <button type="button" (click)="editTable('hoverable')">
                            С выделением строки
                        </button>
                        <button type="button" class="ml-2" (click)="addColToTable()">
                            <i class="fas fa-plus"></i> Колонка
                        </button>
                        <button type="button" (click)="removeColOfTable()">
                            <i class="fas fa-times"></i> Колонка
                        </button>
                        <button type="button" (click)="addRowToTable()">
                            <i class="fas fa-plus"></i> Ряд
                        </button>
                        <button type="button" (click)="removeRowOfTable()">
                            <i class="fas fa-times"></i> Ряд
                        </button>
                    </div>
                </div>
            </div>

            <div #content class="form-control content mt-3 h-auto"
                 contenteditable>
            </div>
            <hr class="my-0">
            <app-files-list [filesHandler]="filesHandler"
                            [forEdit]="true"
            ></app-files-list>
        </div>
    </div>
</div>

