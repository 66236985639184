<app-navbar (toggleSidebar)="toggleSidebar.next()" [ngClass]="navbarClass" >
<!--    <ul>-->
<!--        <li class="nav-item dropdown">-->
<!--            <ng-container *ngTemplateOutlet="support"></ng-container>-->
<!--        </li>-->
<!--    </ul>-->
    <ng-container *ngTemplateOutlet="support"></ng-container>
    <ng-container *ngTemplateOutlet="logout"></ng-container>
    <ul *ngIf="userInfo$ | async as userInfo">
        <li class="nav-item dropdown">
            <a class="nav-lin text-white" [routerLink]="'/u/'+userInfo.email">
                <ng-container *ngTemplateOutlet="avatar; context: { $implicit: userInfo }"></ng-container>
            </a>
            <!--<div class="dropdown-menu dropdown-menu-right" *dropdownMenu>-->
            <!--    <span class="dropdown-item d-flex flex-wrap" style="pointer-events: none;">-->
            <!--        <h5 class="mb-1">{{ userInfo.name }}</h5>-->
            <!--        <div class="w-100"></div>-->
            <!--        <span *ngIf="userInfo.employee && userInfo.employee.portal_position_name"-->
            <!--              [title]="userInfo.employee.portal_position_name"-->
            <!--              class="ru_position_name">-->
            <!--            {{ userInfo.employee.portal_position_name }}-->
            <!--        </span>-->
            <!--    </span>-->
            <!--    <hr class="my-2">-->
            <!--    <ng-container *ngIf="userInfo.is_employee">-->
            <!--        <button class="dropdown-item"-->
            <!--                (click)="changeAbout()">-->
            <!--            Заполнить "о себе"-->
            <!--        </button>-->
            <!--        <button class="dropdown-item"-->
            <!--                (click)="openPhotoModal(userInfo.employee.photo_file_guid, changeAvatarModal)">-->
            <!--            Загрузить фото-->
            <!--        </button>-->
            <!--    </ng-container>-->
            <!--    <div *ngIf="isAdmin" class="mx-2 mt-3">-->
            <!--        <a class="btn btn-primary w-100" [routerLink]="'/login'"><i class="fa fa-power-off"></i> Выйти</a>-->
            <!--    </div>-->
            <!--</div>-->
        </li>
    </ul>
</app-navbar>
<ng-template #support>
    <a href="mailto:portalsupport@mediainstinctgroup.ru" class="btn btn-navbar">
        <i class="fas fa-hands-helping"></i> Поддержка портала
    </a>
</ng-template>
<ng-template #logout>
    <a [routerLink]="" (click)="handleLogoutLinkClick()" class="btn btn-navbar" tooltip="Выйти">
        <i class="fas fa-sign-out-alt"></i>
    </a>
</ng-template>
<ng-template #avatar let-userInfo>
    <h5 class="profile-pic d-none d-lg-block">
        <span class="centered">{{ userInfo.name.slice(0, 1) }}</span>
        <app-user-photo [photoUrl]="userInfo?.employee?.photo_url" [login]="userInfo?.email" class="centered"></app-user-photo>
    </h5>
</ng-template>
<ng-template #changeAvatarModal>
    <app-modal [title]="'Загрузка фото'" [modalRef]="modalRef">
        <div class="p-3">
            <app-image-cropper
                    [image]="photo"
                    (save)="savePhoto($event)"
            ></app-image-cropper>
        </div>
    </app-modal>
</ng-template>
