import { DOCUMENT } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Inject,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { FieldType } from '@ngx-formly/core';
import { document } from 'ngx-bootstrap/utils';
import { ToastrService } from 'ngx-toastr';
import { FilesHandler, FilesHandlerFactory } from 'portal/chunks/files/files-handler/files-handler.service';
import { FilesV3Service } from 'portal/chunks/files/services/files-v3.service';
import { Command, FormatBlockCommands } from 'portal/pages/articles/editor/content-editable.service';
import { fromEvent, merge, Observable, of, Subscription } from 'rxjs';
import { first, mapTo } from 'rxjs/operators';
import { TextEditorService } from 'shared/modules/forms/text-editor/text-editor.service';
import { ModalService } from 'shared/modules/modal/modal.service';
import { getFile } from 'shared/utils/utils';
import * as uuid from 'uuid';

export type blocksStructureType = {
    x: number;
    y: number;
};
const colors = () => {
    const computedStyle = getComputedStyle(document.documentElement);
    const bootstrapColorNames = [
        '--gray-dark',
        '--gray',
        '--secondary',
        '--primary',
        '--success',
        '--info',
        '--warning',
        '--danger',
        '--light',
        '--dark',
        '--blue',
        '--indigo',
        '--purple',
        '--pink',
        '--red',
        '--orange',
        '--yellow',
        '--green',
        '--teal',
        '--cyan',
    ];
    const colors = bootstrapColorNames.map(it => computedStyle.getPropertyValue(it));
    return Array.from(new Set(colors));
};

const blockOptions = Object.values(FormatBlockCommands).map(command => {
    const lastCommandChar = command.slice(-1);
    const isHeading = !isNaN(parseInt(lastCommandChar, 10));
    const isP = command === 'p';
    const isPre = command === 'pre';

    const label = isHeading ? `Заголовок ${lastCommandChar}` :
        isP ? 'Абзац' :
            isPre ? 'Моноширинный' : '';
    return {
        label,
        value: command,
        tag: command,
    };
});

export type ButtonsMenu = Array<Array<{ icon?: string, command: Command, tooltip?: string }>>;

@Component({
    selector: 'app-text-editor',
    templateUrl: './text-editor.component.html',

})
export class TextEditorComponent extends FieldType implements OnInit, OnChanges, AfterViewInit, OnDestroy {
    @ViewChild('content', { static: true }) contentEl: ElementRef<HTMLDivElement>;
    @ViewChild('iframelyPreviewTpl', { static: true }) iframelyPreviewTpl: TemplateRef<any>;
    filesHandler: FilesHandler;
    textEditorService: TextEditorService;
    TextEditorService = TextEditorService;

    headingHaveFocus$: Observable<boolean>;

    blocksStructure: blocksStructureType[];
    buttonsOfBlocksX: number;
    buttonsOfBlocksY: number;

    tableId: string;
    idCurrentTable: string;

    imageId: string;
    idCurrentImage: string;

    @ViewChild('colorPickersPanel') colorPickersPanelEl: ElementRef<HTMLElement>;
    openedPicker: 'background' | 'foreground';

    buttonsMenu: ButtonsMenu = [
        [
            {
                icon: 'fa fa-undo',
                command: 'undo',
                tooltip: 'Отменить',
            },
            {
                icon: 'fa fa-redo',
                command: 'redo',
                tooltip: 'Отменить отмену',
            },
        ],
        [
            {
                icon: 'fa fa-bold',
                command: 'bold',
                tooltip: 'Полужирный',
            },
            {
                icon: 'fa fa-italic',
                command: 'italic',
                tooltip: 'Курсив',
            },
            {
                icon: 'fa fa-underline',
                command: 'underline',
                tooltip: 'Подчеркнутый',
            },
            {
                icon: 'fa fa-strikethrough',
                command: 'strikeThrough',
                tooltip: 'Зачеркнутый',
            },
        ],
        [
            {
                icon: 'fa fa-align-left',
                command: 'justifyLeft',
                tooltip: 'По левому краю',
            },
            {
                icon: 'fa fa-align-center',
                command: 'justifyCenter',
                tooltip: 'По центру',
            },
            {
                icon: 'fa fa-align-right',
                command: 'justifyRight',
                tooltip: 'По правому краю',
            },
            {
                icon: 'fa fa-align-justify',
                command: 'justifyFull',
                tooltip: 'По ширине',
            },
        ],
        [
            {
                icon: 'fa fa-indent',
                command: 'indent',
                tooltip: 'Отступ вправо',
            },
            {
                icon: 'fa fa-outdent',
                command: 'outdent',
                tooltip: 'Отступ влево',
            },
        ],
        [
            {
                icon: 'fa fa-list-ul',
                command: 'insertUnorderedList',
                tooltip: 'Маркированный список',
            },
            {
                icon: 'fa fa-list-ol',
                command: 'insertOrderedList',
                tooltip: 'Нумерованный список',
            },
        ],
    ];

    block = undefined;
    blockOptions = blockOptions;
    colors = colors();
    private sub = new Subscription();


    constructor(
        private viewContainerRef: ViewContainerRef,
        private modal: ModalService,
        private fb: FormBuilder,
        private filesV3Service: FilesV3Service,
        private toastr: ToastrService,
        private cdRef: ChangeDetectorRef,
        @Inject(DOCUMENT) private doc: Document,
        filesHandlerFactory: FilesHandlerFactory,
    ) {
        super();

        this.blocksStructure = [
            { x: 0, y: 0 },
            { x: 20, y: 20 },
            { x: 40, y: 40 },
            { x: 60, y: 60 },
            { x: 80, y: 80 },
            { x: 100, y: 100 },
            { x: 120, y: 120 },
            { x: 140, y: 140 },
            { x: 160, y: 160 },
            { x: 180, y: 180 },
            { x: 200, y: 200 },
            { x: 220, y: 220 },
        ];
        this.filesHandler = filesHandlerFactory.createV3();
    }

    ngOnInit() {
        this.textEditorService = new TextEditorService(this.contentEl.nativeElement);
        this.headingHaveFocus$ = merge(
            fromEvent(this.contentEl.nativeElement, 'focus').pipe(mapTo(false)),
        );
        this.ngOnChanges();
    }

    ngOnChanges(changes?: SimpleChanges) {
        this.contentEl.nativeElement.addEventListener('click', (e) => {
            const currentTable = document.getElementById(this.idCurrentTable);
            if (!currentTable?.contains(e.target)) {
                document.getElementById(this.idCurrentTable)?.classList.remove('choosen-element');
                this.idCurrentTable = '';
            }
        });

        this.contentEl.nativeElement.addEventListener('click', (e) => {
            const currentImage = document.getElementById(this.idCurrentImage);
            if (!currentImage?.contains(e.target)) {
                document.getElementById(this.idCurrentImage)?.classList.remove('choosen-element');
                this.idCurrentImage = '';
            }
        });

        this.contentEl.nativeElement.innerHTML = this.formControl.value;

        this.contentEl.nativeElement.addEventListener('input', (e) => {
            this.save();
        }, false);

        /*this.filesHandler.addFilesFromServer(this.formControl.value.file_list || []);
         для возможности принимать загруженные файлы*/
    }

    ngAfterViewInit() {
        const sub = fromEvent(this.doc, 'click')
            .subscribe((e: MouseEvent) => {
                if (this.colorPickersPanelEl?.nativeElement.contains(e.target as Node)) {
                    return;
                }
                this.openedPicker = undefined;
                this.cdRef.detectChanges();
            });
        this.sub.add(sub);
        this.setEventListener('img');
        this.setEventListener('table');
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
        this.textEditorService.dispose();
    }

    execute(c: Command) {
        this.textEditorService.executeCommand(c);
    }

    setEventListener(tagName: string) {
        const arr = this.contentEl.nativeElement.getElementsByTagName(tagName);
        for (let i = 0; i < arr.length; i++) {
            document.getElementById(arr[i].id).addEventListener('click', (event) => this.getCurrentElement(event, tagName));
        }

    }

    changeStyleCellOfButtonOfBlocks(event: any, id: string) {
        const x = document.getElementById(id).getBoundingClientRect().x;
        const y = document.getElementById(id).getBoundingClientRect().y;
        this.buttonsOfBlocksX = event.clientX - x;
        this.buttonsOfBlocksY = event.clientY - y;
    }

    getCurrentElement(event: any, element: string) {
        if (!!event.target.id) {
            switch (element) {
                case 'img':
                    this.idCurrentImage = event.target.id;
                    this.chooseElement(this.idCurrentImage);
                    break;
                case 'table':
                    this.idCurrentTable = event.target.id;
                    this.chooseElement(this.idCurrentTable);
                    break;
            }
        }
    }

    chooseElement(id: string) {
        if (document.getElementsByClassName('choosen-element').length === 0) {
            document.getElementById(id)?.classList.add('choosen-element');
        } else {
            document.querySelector('.choosen-element')?.classList.remove('choosen-element');
            document.getElementById(id)?.classList.add('choosen-element');
        }
    }

    addBreak() {
        this.textEditorService.insertBreak();
    }

    createTable(event: any) {
        const x = document.getElementById('buttonsOfTable').getBoundingClientRect().x;
        const y = document.getElementById('buttonsOfTable').getBoundingClientRect().y;
        const cols = Math.ceil((event.clientX - x) / 20);
        const rows = Math.ceil((event.clientY - y) / 20);
        this.tableId = uuid.v4();

        let htmlHead = `<br><table id="${this.tableId}" class="table table-striped"><thead><tr>`;
        for (let i = 0; i < cols; i++) {
            htmlHead = htmlHead + `<th>Текст</th>`;
        }
        htmlHead = htmlHead + `</tr></thead>`;
        let htmlBody = `<tbody>`;
        for (let i = 0; i < rows - 1; i++) {
            htmlBody = htmlBody + `<tr><th>Текст</th>`;
            for (let i = 0; i < cols - 1; i++) {
                htmlBody = htmlBody + `<td>Текст</td>`;
            }
            htmlBody = htmlBody + `</tr>`;
        }
        htmlBody = htmlBody + `</tbody></table>`;

        const html = htmlHead + htmlBody + `<br>`;

        this.textEditorService.insertHTML(html, true);
        document.getElementById(this.tableId)?.addEventListener('click', (event) => this.getCurrentElement(event, 'table'));
    }

    editTable(action: string) {
        const el = document.getElementById(this.idCurrentTable);
        const arrayClasses = el.classList.value.split(' ');
        arrayClasses.shift();
        el.classList.remove(...arrayClasses);
        switch (action) {
            case 'standart':
                break;
            case 'striped':
                el.classList.add('table-striped');
                break;
            case 'hoverable':
                el.classList.add('table-hover');
                break;
        }
        this.idCurrentTable = '';
    }

    addColToTable() {
        if (document.getElementById(this.idCurrentTable).querySelectorAll('thead th').length < 12) {
            const arrThead = document.getElementById(this.idCurrentTable).querySelectorAll('thead tr');
            arrThead.forEach((thead) => {
                const th = document.createElement('th');
                th.innerHTML = 'Текст';
                thead.append(th);
            });
            const arrTbody = document.getElementById(this.idCurrentTable).querySelectorAll('tbody tr');
            arrTbody.forEach((tbody) => {
                const td = document.createElement('td');
                td.innerHTML = 'Текст';
                tbody.append(td);
            });
        }
    }

    addRowToTable() {
        if (document.getElementById(this.idCurrentTable).querySelectorAll('tbody tr').length < 11) {
            const numberCols = document.getElementById(this.idCurrentTable).querySelectorAll('thead th').length;
            const arrTbody = document.getElementById(this.idCurrentTable).querySelectorAll('tbody');
            arrTbody.forEach((tbody) => {
                const tr = document.createElement('tr');
                let trInnerHtml = `<th>Текст</th>`;
                for (let i = 1; i < numberCols; i++) {
                    trInnerHtml = trInnerHtml + `<td>Текст</td>`;
                }
                tr.innerHTML = trInnerHtml;
                tbody.append(tr);
            });
        }
    }

    removeColOfTable() {
        const numberCols = document.getElementById(this.idCurrentTable).querySelectorAll('thead th').length;
        if (numberCols > 1) {
            const arrThead = document.getElementById(this.idCurrentTable).querySelectorAll('thead tr');
            arrThead.forEach((thead) => {
                thead.getElementsByTagName('th')[numberCols - 1].outerHTML = '';
            });
            const arrTbody = document.getElementById(this.idCurrentTable).querySelectorAll('tbody tr');
            arrTbody.forEach((tbody) => {
                tbody.getElementsByTagName('td')[numberCols - 2].outerHTML = '';
            });
        }
    }

    removeRowOfTable() {
        const numberRows = document.getElementById(this.idCurrentTable).querySelectorAll('tbody tr').length;
        if (numberRows > 0) {
            const arrTbody = document.getElementById(this.idCurrentTable).querySelectorAll('tbody');
            arrTbody.forEach((tbody) => {
                tbody.getElementsByTagName('tr')[numberRows - 1].outerHTML = '';
            });
        }
    }

    createGrid(event: any) {
        const x = document.getElementById('buttonsOfGrid').getBoundingClientRect().x;
        const y = document.getElementById('buttonsOfGrid').getBoundingClientRect().y;
        const cols = Math.ceil((event.clientX - x) / 20);
        const rows = Math.ceil((event.clientY - y) / 20);

        const htmlHead = `<div class="container-fluid">`;
        let htmlBody = ``;
        for (let i = 0; i < rows; i++) {
            htmlBody = htmlBody + `<div class="row">`;
            for (let i = 0; i < cols; i++) {
                htmlBody = htmlBody + `<div class="col">Колонка ${i + 1}</div>`;
            }
            htmlBody = htmlBody + `</div>`;
        }
        const html = htmlHead + htmlBody + `</div>`;
        return of(this.textEditorService.insertHTML(html, true));

    }

    addImage() {
        this.imageId = uuid.v4();
        getFile(files => {
            const image = files.item(0);
            this.filesV3Service.upload(image)
                .subscribe(fileInfo => {
                    this.textEditorService.insertImage(fileInfo.url, this.imageId);
                    document.getElementById(this.imageId)?.addEventListener('click', (event) => this.getCurrentElement(event, 'img'));
                });
        });

    }

    editImage(action: string) {
        const el = document.getElementById(this.idCurrentImage);
        const classList = el.classList;
        if (classList.length > 0) {
            const arrayClasses = classList.value.split(' ');
            el.classList.remove(...arrayClasses);
        }
        const image = el.outerHTML;
        switch (action) {
            case 'square':
                break;
            case 'circle':
                el.classList.add('rounded-circle');
                break;
            case 'border-square':
                el.classList.add('img-thumbnail');
                break;
            case 'border-circle':
                el.classList.add('img-thumbnail');
                el.classList.add('rounded-circle');
                break;
        }
        this.idCurrentImage = '';
    }

    addLink() {
        const selection = window.getSelection().toString();
        this.modal.createForm({
            title: 'Введите URL',
            form: [{
                type: 'input',
                key: 'url',
                templateOptions: {
                    required: true,
                    hideRequiredMarker: true,
                    focusAfterRender: true,
                },
            }],
            onSubmit: ({ url }) => {
                const text = selection;
                const html = `<a href="${url}" target="_blank">${text}</a>`;
                return of(this.textEditorService.insertHTML(html, true));
            },
        });
    }

    addCallToAction() {
        this.modal.createForm({
            title: 'Введите информацию',
            form: [
                {
                    type: 'input',
                    key: 'action',
                    templateOptions: {
                        label: 'Действие',
                        required: true,
                    },
                },
                {
                    type: 'input',
                    key: 'link',
                    templateOptions: {
                        label: 'Ссылка',
                        required: true,
                    },
                },
            ],
            onSubmit: ({ action, link }) => {
                const html = `  <br>  
                                <div class="alert alert-primary">
                                    <div class="d-flex align-items-center justify-content-between">
                                        <div class="w-50">
                                            <h4 class="alert-heading">Заголовок</h4>
                                            <p>Введите текст...</p>
                                        </div>
                                        <div>
                                            <a href="${link}" target="_blank" class="btn btn-primary">${action}</a>
                                        </div>
                                    </div>
                                 </div>
                                 <br>  
                            `;

                return of(this.textEditorService.insertHTML(html, true));
            },
        });
    }

    addIFrame() {
        this.modal.createForm({
            title: 'Введите информацию',
            form: [
                {
                    type: 'input',
                    key: 'link',
                    templateOptions: {
                        label: 'Ссылка',
                        required: true,

                    },
                },
            ],
            onSubmit: ({ link }) => {
                const html = `  <br>
                                <div class="embed-responsive embed-responsive-16by9">
                                      <iframe src="${link}" class="embed-responsive-item"></iframe>
                                </div>
                                <br>
                            `;

                return of(this.textEditorService.insertHTML(html, true));
            },
        });
    }

    addFile() {
        getFile(
            files => this.filesHandler.addFilesFromClient(files),
            { multiple: true, accept: '*/*' },
        );
    }

    async save() {
        const content = this.contentEl.nativeElement.innerHTML;
        this.filesHandler.uploadFiles$().pipe(first()).toPromise();
        const file_guids = await this.filesHandler.uploadFiles$().pipe(first()).toPromise();
        const result = {
            content: content,
            file_guid_list: file_guids,
        };
        if (file_guids.length !== 0) {
            this.formControl.patchValue(result);
        } else {
            this.formControl.patchValue(content);
        }

    }

}
