import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { EmployeeApiModel } from 'portal/pages/main/employees/employees.interfaces';
import { EmployeesFetchService } from 'portal/pages/main/employees/employees.service';
import { PointsService, WorkplaceInfoForEmployee } from 'portal/pages/main/place-booking/old-map/map/points/points.service';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { saveAs } from 'shared/utils/utils';

const clearHeadEmployeeIfHeadEmployeeEqualsEmployeeItself = () => map((employee: EmployeeApiModel) => {
    if (employee.display_name === employee.head_employee_display_name) {
        employee.head_employee_display_name = null;
    }
    return employee;
});

@Component({
    selector: 'app-employee-modal',
    templateUrl: './employee-modal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeeModalComponent implements OnDestroy {
    private _employee: EmployeeApiModel;
    get employee() { return this._employee; }

    set employee(e: EmployeeApiModel) {
        this._employee = e;
        this.setWorkplaceInfo();
        this.cdRef.detectChanges();
        document.querySelector('.modal-dialog').classList.add('modal-employee');
    }

    get birthDate() {
        if (!!this.employee === false) {
            return null;
        }
        return new Date(new Date().getFullYear(), this.employee.birth_month - 1, this.employee.birth_day);
    }

    workplaceInfoForEmployee$: Observable<WorkplaceInfoForEmployee>;
    private employees: EmployeeApiModel[] = [];

    destroyed$ = new Subject();

    constructor(private employeesFetchService: EmployeesFetchService,
                private pointsService: PointsService,
                private cdRef: ChangeDetectorRef,
                private ref: BsModalRef,
                private router: Router
    ) {
    }


    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    get canGoBack() {
        return this.employees.length > 0;

    }

    private setWorkplaceInfo() {
        this.workplaceInfoForEmployee$ = this.pointsService.getPointInfoForEmployee$(this.employee.id);
    }

    async openHead(e: Event) {
        e.preventDefault();
        this.employees = [this.employee, ...this.employees];
        this.employee = await this.employeesFetchService
            .get({ id: this.employee.head_employee_id })
            .pipe(clearHeadEmployeeIfHeadEmployeeEqualsEmployeeItself())
            .toPromise();
    }

    goBack() {
        [this.employee, ...this.employees] = this.employees;
        this.cdRef.detectChanges();
    }

    saveVcard() {
        const vcardParts = `BEGIN:VCARD
VERSION:3.0
N:${this.employee.surname};${this.employee.name};;
FN:${this.employee.display_name}
ORG:${this.employee.agency_name}
TITLE:${this.employee.portal_position_name}
PHOTO;GIF:${this.employee.photo_url}
TEL;CELL;VOICE:+${this.employee.mobile_phone}
EMAIL;WORK:${this.employee.email}
END:VCARD`;
        const vcardBlob = new Blob([vcardParts], { type: 'data:text/x-vcard;charset=utf8;' });
        saveAs(vcardBlob, `${this.employee.display_name}.vcf`);
    }

    goToUserProfile(login) {
        this.router.navigateByUrl(`/u/${login}`).then(() => this.ref.hide());
    }
}
