import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { isEqual } from 'lodash-es';
import { BookingHeaderCellComponent } from 'portal/pages/main/booking/booking-header-cell/booking-header-cell';
import { PermissionsService, permStub } from 'portal/services/permissions.service';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { distinctUntilChanged, finalize, map, mergeMap, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { CellData, ColumnConfig } from 'shared/modules/table/table-config.model';
import { Utils } from 'shared/utils/utils';
import { isNullOrUndefined } from 'shared/utils/utils';
import { Booking, BookingRow, PlaceCol, SelectedCells } from './booking.interfaces';
import { TimeFromTimeToCell } from './time-from-time-to-cell/time-from-time-to-cell';

// noinspection DuplicatedCode
@Injectable()
export class BookingService {
    private selectedDateSubject$ = new BehaviorSubject<Date>(Utils.now);
    cellSize = 0;

    get selectedDate$() {
        return this.selectedDateSubject$.asObservable();
    }

    private selectedCellsSubject$ = new BehaviorSubject<SelectedCells>({});

    rowsAndColumns$: Observable<{ columns: ColumnConfig<PlaceCol>[], rows: BookingRow[] }> = this.selectedDateSubject$
        .asObservable()
        .pipe(
            switchMap((selectedDate) => {
                return this.http.post('/api/booking/get_booking_timetable', { date: Utils.dateToBackend(selectedDate) })
                    .pipe(
                        tap(() => this.selectedCellsSubject$.next({})),
                        map((res: any) => {
                            const placeColumns: ColumnConfig<PlaceCol>[] = res.data.header
                                .map((data: PlaceCol) => ({
                                    prop: `item.${data.id}`,
                                    headerComponent: BookingHeaderCellComponent,
                                    component: this.cmp,
                                    data,
                                }));
                            const columns: ColumnConfig<PlaceCol>[] = [{ name: 'Время', component: TimeFromTimeToCell }, ...placeColumns];
                            this.prepareSelectedCellsDataStructure(placeColumns);
                            const rows = this.computeRows(res);
                            this.computeRowSpans(rows);
                            this.cellSize = rows[0].time_to - rows[0].time_from;
                            return { columns, rows };
                        }),
                    );
            }),
            shareReplay(1),
        );

    // templateInfoForm$ = this.rowsAndColumns$.pipe(map(({ rows, columns }) => {
    //     console.log(columns);
    //     const map-places = columns
    //         .filter(col => col.data)
    //         .map(col => ({ label: col.data.name, value: col.data.id }));
    //     const minTimeFrom = rows[0].time_from;
    //     const minTimeTo = rows[0].time_to;
    //     const maxTimeFrom = rows[rows.length - 1].time_from;
    //     const maxTimeTo = rows[rows.length - 1].time_to;
    //     const step = this.cellSize;
    //     return { minTimeFrom, minTimeTo, maxTimeFrom, maxTimeTo, step, map-places };
    // }));

    bookings$: Observable<Booking[]> = this.selectedCellsSubject$
        .asObservable()
        .pipe(
            map((selectedCells) => {
                let bookings: Booking[] = [];
                // Iterate over dates and map-places
                Object.keys(selectedCells).forEach((placeId) => {
                    // Constant part of query
                    const bookingTemplate: Booking = {
                        date: Utils.dateToBackend(this.selectedDateSubject$.value),
                        place_id: parseInt(placeId, 10),
                        time_from: 0,
                        time_to: 0,
                    };
                    // array of timeFrom F.E. [600, 630, 750]
                    const timeFromArray = Object.keys(selectedCells[placeId])
                        .filter(timeFrom => selectedCells[placeId][timeFrom])
                        .map(timeFrom => parseInt(timeFrom, 10))
                        .sort((a, b) => a > b ? 1 : -1);
                    // if some cells are selected
                    if (timeFromArray.length) {
                        const bookingsForPlace = timeFromArray.reduce<Booking[]>(
                            (arrayOfBookings, time_from) => {
                                const lastEntry = arrayOfBookings[arrayOfBookings.length - 1];

                                const isFirstIteration = lastEntry.time_from === 0;
                                const nextCellIsAfterOther = lastEntry.time_to === time_from;

                                if (isFirstIteration) {
                                    lastEntry.time_from = time_from;
                                    lastEntry.time_to = time_from + this.cellSize;
                                } else if (nextCellIsAfterOther) {
                                    lastEntry.time_to = time_from + this.cellSize;
                                } else { // create new entry
                                    arrayOfBookings.push(
                                        {
                                            ...bookingTemplate,
                                            ...{ time_from, time_to: time_from + this.cellSize },
                                        },
                                    );
                                }
                                return arrayOfBookings;
                            },
                            [{ ...bookingTemplate }],
                        );

                        bookings = bookings.concat(bookingsForPlace);

                    }
                });
                return bookings;
            }),
        );

    showCancelBookings$ = this.getShowCancelBookings$();

    constructor(private http: HttpClient, private cmp: any, private permissionsService: PermissionsService) {
    }

    setSelectedDate(val: Date) {
        this.selectedDateSubject$.next(val);
    }

    private computeRows(res: any) {
        return res.data.rows.map((x) => {
            return { ...x.places, time_from: x.time_from, time_to: x.time_to, rowspan: {} };
        });
    }

    private computeRowSpans(rows: BookingRow[]) {
        const placesIds = Object.keys(rows[0])
            .map(x => parseInt(x, 10))
            .filter(x => !!x);

        const memory: { [placeId: number]: { value?: string, index?: number } } =
            placesIds.map(placeId => ({ [placeId]: {} })).reduce((a, b) => ({ ...a, ...b }), {});

        for (let i = 0; i < rows.length; i++) {
            for (const placeId of placesIds) {
                const memoryForPlace = memory[placeId];
                const rowValue = rows[i][placeId];
                if (isNullOrUndefined(rowValue)) { // Free cell
                    memoryForPlace.value = undefined;
                    memoryForPlace.index = undefined;

                } else if (isEqual(memoryForPlace.value, rowValue)) { // Current cell same as memoized
                    rows[i].rowspan[placeId] = 'none';
                    rows[memory[placeId].index].rowspan[placeId] = (rows[memory[placeId].index].rowspan[placeId] as number) + 1 || 2;
                } else { // memoize cell
                    memoryForPlace.value = rowValue;
                    memoryForPlace.index = i;
                }
            }
        }
    }

    // tslint:disable-next-line:max-line-length
    createTemplateInfoForm$(): Observable<{ minTimeFrom: number; maxTimeFrom: number; places: { label: string; value: number }[]; minTimeTo: number; step: number; maxTimeTo: number }> {
        return this.rowsAndColumns$.pipe(map(({ rows, columns }) => {
            const places = columns
                .filter(col => col.data)
                .map(col => ({ label: col.data.name, value: col.data.id }));
            const minTimeFrom = rows[0].time_from;
            const minTimeTo = rows[0].time_to;
            const maxTimeFrom = rows[rows.length - 1].time_from;
            const maxTimeTo = rows[rows.length - 1].time_to;
            const step = this.cellSize;
            return { minTimeFrom, minTimeTo, maxTimeFrom, maxTimeTo, step, places };
        }));
    }

    isCellSelected$(data: CellData<BookingRow, PlaceCol>): Observable<boolean> {
        const placeId = data.column.data.id;
        const time = data.row.time_from;
        return this.selectedCellsSubject$.pipe(
            map((selectedCells) => selectedCells[placeId] && selectedCells[placeId][time]),
            distinctUntilChanged(),
        );
    }

    isCellAvaliable(data: CellData<BookingRow, PlaceCol>) {
        if (data.value) {
            return false;
        }
        if (Utils.isToday(this.selectedDateSubject$.value)) {
            const timeNow = new Date;
            const timeInRow = new Date;
            timeInRow.setHours(0, 0, 0, 0);
            timeInRow.setMinutes(data.row.time_from + this.cellSize);
            return timeNow < timeInRow;
        }
        return true;
    }

    toggleSelected(data: CellData<BookingRow, PlaceCol>) {
        const placeId = data.column.data.id;
        const time = data.row.time_from;
        const selectedCells = this.selectedCellsSubject$.value;
        selectedCells[placeId][time] = !selectedCells[placeId][time];

        this.selectedCellsSubject$.next(selectedCells);
    }

    private prepareSelectedCellsDataStructure(placeColumns: ColumnConfig<PlaceCol>[]) {
        placeColumns.forEach((col) => {
            const placeId = col.data.id;
            if (isNullOrUndefined(this.selectedCellsSubject$.value)) {
                this.selectedCellsSubject$.next({});
            }
            if (isNullOrUndefined(this.selectedCellsSubject$.value[placeId])) {
                this.selectedCellsSubject$.value[col.data.id] = {};
            }
        });
    }

    makeBookings() {
        return this.bookings$.pipe(
            take(1),
            switchMap(x => of(...x)),
            mergeMap((booking) => this.http.post('/api/booking/make_booking', booking)),
            finalize(() => this.reloadTimetable()),
        );
    }

    cancelBookings(reqBodies: { id: number }[]) {
        return forkJoin(reqBodies.map(body => this.http.post('/api/booking/cancel_booking', body))).pipe(
            switchMap(() => {
                this.reloadTimetable();
                return this.rowsAndColumns$.pipe(take(2));
            }),
        );
    }

    reloadTimetable() {
        this.selectedDateSubject$.next(this.selectedDateSubject$.value);
        this.showCancelBookings$ = this.getShowCancelBookings$();
    }

    private getShowCancelBookings$(): Observable<boolean> {
        const canCancelAny = this.permissionsService.getPermissionValue(permStub.booking.timetable.cancelAny);
        return canCancelAny
            ? of(true)
            : this.http.post<any>('/api/booking/get_booking_list', {}).pipe(
                map(res => !!res.data.total),
            );
    }
}
