<button *ngIf="navbarContent.showToggler$ | async" class="navbar-toggler border-0" type="button"
        aria-label="Toggle navigation" (click)="toggle()">
    <span class="navbar-toggler-icon"></span>
</button>
<ng-content select=".navbar-left"></ng-content>
<ng-container *ngIf="(navbarContent.template$ | async) as template; else widthStub">
    <ng-template [ngTemplateOutlet]="template"></ng-template>
</ng-container>
<ng-template #widthStub>
    <div class="w-100"></div>
</ng-template>
<ng-content></ng-content>
