import { Component, OnInit } from '@angular/core';
import { FieldArrayType, FormlyFormBuilder } from '@ngx-formly/core';
import { range } from 'shared/utils/range';

@Component({
  selector: 'formly-repeat-section',
  template: `
    <div class="d-flex align-items-center pb-3">
        {{ label }}
      <button class="ml-auto btn btn-primary" type="button" (click)="add(0)" [disabled]="formControl.invalid || length >= maxLength">
        <i class="fa fa-plus"></i>
        <span> {{ btnText }} </span>
      </button>
    </div>
    <div *ngFor="let field of field.fieldGroup; let i = index" class="d-flex flex-nowrap {{ repeaterClass }}">
      <formly-group
        [model]="model[i]"
        [field]="field"
        [options]="options"
        [form]="formControl">
      </formly-group>
        <button *ngIf="!required || length > 1"
                class="bg-danger shadow-sm text-white rounded-circle border-0 mb-3 ml-3 align-self-center"
                style="cursor: pointer"
                type="button"
                (click)="remove(i)">
            <i class="fa fa-trash"></i>
        </button>
    </div>
  `,
})
export class RepeatTypeComponent extends FieldArrayType implements OnInit {
  get label() {
    return (this.field.fieldArray.templateOptions as any || {}).label;
  }

  get btnText() {
    return (this.field.fieldArray.templateOptions as any || {}).btnText;
  }

  get maxLength() {
    return (this.field.fieldArray.templateOptions as any || {}).maxLength;
  }

  get required() {
    return (this.field.fieldArray.templateOptions as any || {}).required;
  }

  get repeaterClass() {
    return (this.field.fieldArray.templateOptions as any || {}).repeaterClass;
  }

  get length() {
    return this.formControl.length;
  }

  constructor(builder: FormlyFormBuilder) {
    super(builder);
  }

  ngOnInit(): void {
    const value: any[] = this.formControl.value;
    if (value && value.length) {
      // Somehow length of formarray persists. This cannot hanlde nested structure;
      const emptyLength = value
        .map(it => Object.keys(it)
          .map(k => it[k])
          .reduce((a, b) => a || b, false))
        .filter(it => !it)
        .length;

      range(emptyLength).forEach(() => {
        this.remove(this.length - 1);
      });
    }
    if (!this.length && this.required) {
      this.add();
    }
  }
}
