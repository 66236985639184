import {HttpClient} from '@angular/common/http';
import {AfterViewInit, Component} from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {iif, Observable, of} from 'rxjs';
import {filter, map, mergeMap, switchMap, take, tap} from 'rxjs/operators';
import {ApiResponse} from 'shared/interfaces/response';
import {ModalService} from 'shared/modules/modal/modal.service';
import {FormUtils} from 'shared/utils/form.utils';
import {AuthService, PasswordChangeUrl} from '../../services/auth.service';
import {ErrorInterceptor} from '../../services/interceptors/error.interceptor';
import {PermissionsService} from '../../services/permissions.service';

const SecurePasswordValidator = {
    expression: ({value}: FormControl) => {
        const latinLetters = 'a-z';
        const numbers = '0-9';
        const specialSymbols = '!"#$%&\'()*+,-./:;<=>?@^_`{}~';
        const test = regexPart => new RegExp(`[${regexPart}]+`).test(value);
        return value.length >= 6 && test(latinLetters) && test(numbers) && test(specialSymbols);
    },
    message: () => 'Пароль должен состоять минимум из 6и символов. Содержать цифры, буквы и спец. символы',
};

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
})
export class LoginComponent implements AfterViewInit {

    form = this.fb.group({
        login: new FormControl('', Validators.required),
        password: new FormControl('', Validators.required),
    });
    error: string;

    $ip: Observable<string> = this.http.get<ApiResponse<{
        ip: string,
        ip_list: { HTTP_X_FORWARDED_FOR: string }
    }>>('/api/v2/user/myip').pipe(
        map(response => response.data.ip_list.HTTP_X_FORWARDED_FOR),
    );

    $success = this.route.queryParams.pipe(map(query => query['success']));

    constructor(
        private auth: AuthService,
        private permissions: PermissionsService,
        private fb: FormBuilder,
        private modalService: ModalService,
        private toastrService: ToastrService,
        private route: ActivatedRoute,
        private router: Router,
        private http: HttpClient,
    ) {
        AuthService.removeToken();
        this.permissions.removePermissions();

        if (route.snapshot.url.slice(-1)[0].path === PasswordChangeUrl) {
            this.openChangePasswordModal();
        }
    }

    ngAfterViewInit() {

        this.$success.subscribe((success) => {
            if (!!success) {
                this.http.get<ApiResponse<{ token: string }>>(`api/v2/auth-token`)
                  .pipe(
                    map(response => response.data.token),
                  )
                  .subscribe((token) => {
                      AuthService.token = token;
                      this.router.navigateByUrl('/dashboard');
                });
            } else {
                this.http.get<ApiResponse<{ url: string }>>(`api/v2/auth-params`)
                  .pipe(
                    map(response => response.data.url),
                    filter(url => !!url),
                    tap(url => window.location.href = url)
                  ).subscribe(() => {});
            }
        });
    }

    login() {
        this.error = undefined;
        if (this.form.valid) {
            ErrorInterceptor.skipError(1);
            const {login, password} = this.form.value;
            this.auth.login(login, password).subscribe(
                () => null,
                err => {
                    this.error = err.error.error_message;
                },
            );
        } else {
            FormUtils.markFormDirty(this.form);
            this.form.statusChanges.pipe(
                take(1),
                tap(() => this.error = ''),
            ).subscribe();
            this.error = 'Все поля обязательны для заполнения';
        }
    }

    openNewPasswordModal() {
        this.modalService.createForm({
            title: 'Восстановление пароля',
            buttonTitle: 'Восстановить',
            model: {account: ''},
            form: [
                {
                    key: 'account',
                    type: 'input',
                    templateOptions: {
                        label: 'E-mail',
                        required: true,
                        hideRequiredMarker: true,
                    },
                },
            ],
            onSubmit: account => this.auth.requestPasswordChange(account)
                .pipe(tap(() => this.toastrService.success(
                    'Если будет найдена соответствующая учетная запись, то письмо для восстановления будет отправлено'))),
        });
    }

    openChangePasswordModal() {
        const token = this.route.snapshot.queryParamMap.get('token');
        this.modalService.updateForm({
            title: 'Смена пароля',
            buttonTitle: 'Сменить',
            model: {token, password: ''},
            form: [
                {
                    key: 'token',
                    type: 'input',
                    templateOptions: {
                        label: 'Код из письма',
                        required: true,
                        hideRequiredMarker: true,
                    },
                },
                {
                    key: 'password',
                    type: 'input',
                    templateOptions: {
                        type: 'password',
                        label: 'Новый пароль',
                        required: true,
                        hideRequiredMarker: true,
                    },
                    expressionProperties: {
                        'templateOptions.type': model => model.showPassword ? 'text' : 'password',
                    },
                    validators: {SecurePasswordValidator},
                },
                {
                    key: 'showPassword',
                    type: 'checkbox',
                    defaultValue: false,
                    templateOptions: {label: 'Показать пароль'},
                },
            ],
            onSubmit: ({password, token}) => this.auth.changePassword({new_password: password, token: token})
                .pipe(tap(() => this.toastrService.success('Используйте новый пароль для входа', 'Успешно!'))),
        });
    }

}
