<div class="login-container">
    <div class="login-content card">
        <div class="p-4">
            <h1 class="logo-header">
                <span class="logo-part-one">mi</span>
                <span class="logo-part-two">portal</span>
            </h1>
            <p class="lead text-center">Аутентификация</p>
            <p class="lead text-center"><i class="fa-2x fas fa-circle-notch fa-spin"></i></p>

            <!-- Закомментированная форма аутентификации -->
            <!--
            <form (ngSubmit)="login()" [formGroup]="form">
                <div class="input-wrapper">
                    <i class="fa fa-user"></i>
                    <input
                        id="login"
                        type="text"
                        class="form-control"
                        formControlName="login"
                        placeholder="E-mail" />
                </div>
                <div class="input-wrapper">
                    <i class="fa fa-lock"></i>
                    <input
                        id="password"
                        type="password"
                        class="form-control"
                        formControlName="password"
                        placeholder="Пароль" />
                </div>
                <div class="mb-3 d-flex justify-content-between">
                    <a href="#" (click)="openNewPasswordModal()">Забыли пароль?</a>
                    <a href="#" (click)="openChangePasswordModal()">Ввести код из письма</a>
                </div>
                <ng-container *ngIf="error">
                    <div class="alert alert-danger">
                        {{ error }}
                    </div>
                </ng-container>
                <button type="submit" class="login-btn btn-flat">Войти</button>
            </form>
            -->

            <p *ngIf="$ip | async as ip" class="small lead text-right mb-0 text-muted">
                ip: {{ ip }}
            </p>
        </div>
    </div>
</div>
