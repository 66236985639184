import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {ProgressbarModule} from 'ngx-bootstrap/progressbar';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {ConfigViewerModule} from 'shared/base/config-viewer/config-viewer.module';
import {ColorizationService, IColorizationInterface} from 'shared/modules/table/colorization/colorization.service';
import {DefaultCellModule} from 'shared/modules/table/custom-cells/default-cell/default-cell.module';
import {EmployeeCellComponent} from 'shared/modules/table/custom-cells/employee-cell/employee-cell.component';
import {EndpointCell} from 'shared/modules/table/custom-cells/endpoint-cell/endpoint-cell';
import {FileCell} from 'shared/modules/table/custom-cells/file-cell/file-cell';
import {ImageCell} from 'shared/modules/table/custom-cells/image-cell/image-cell';
import {LinksCell} from 'shared/modules/table/custom-cells/links-cell/links-cell';
import {MapperCell} from 'shared/modules/table/custom-cells/mapper-cell/mapper-cell';
import {OverflowCell} from 'shared/modules/table/custom-cells/overflow-cell/overflow-cell';
import {PeriodCell} from 'shared/modules/table/custom-cells/period-cell/period-cell';
import {ProgressCell} from 'shared/modules/table/custom-cells/progress-cell/progress-cell';
import {FooterWrapperComponent} from 'shared/modules/table/footer-wrapper/footer-wrapper.component';
import {PaginatorModule} from 'shared/modules/table/paginator/paginator.module';
import {BooleanCell} from './custom-cells/boolean-cell/boolean-cell';
import {DateCell} from './custom-cells/date-cell/date-cell';
import {FilterCell} from './custom-cells/filter-cell/filter-cell.component';
import {ColumnHeading} from './directives/column-heading.directive';
import {Footer} from './directives/footer.directive';
import {PaginationWrapperComponent} from './pagination-wrapper/pagination-wrapper.component';
import {SorterComponent} from './sorter/sorter.component';
import {TableComponent} from './table/table.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ConfigViewerModule,
    DefaultCellModule,
    PaginatorModule,
    ProgressbarModule.forRoot(),
    TooltipModule,
    RouterModule,
    TranslateModule,
  ],
  declarations: [
    TableComponent,
    SorterComponent,
    ColumnHeading,
    Footer,
    PaginationWrapperComponent,
    BooleanCell,
    LinksCell,
    DateCell,
    FilterCell,
    MapperCell,
    PeriodCell,
    EmployeeCellComponent,
    ProgressCell,
    OverflowCell,
    ImageCell,
    FooterWrapperComponent,
    FileCell,
    EndpointCell,
  ],
  exports: [
    TableComponent,
    ColumnHeading,
    Footer,
    ConfigViewerModule,
    PaginationWrapperComponent,
    FooterWrapperComponent,
  ],
  entryComponents: [
    DateCell,
    FilterCell,
    MapperCell,
    PeriodCell,
    ProgressCell,
    EmployeeCellComponent,
  ],
  providers: [
    {provide: IColorizationInterface, useClass: ColorizationService},
  ],
})
export class TableModule {
}
