import { Component, Input } from '@angular/core';
import { FilesHandler } from 'portal/chunks/files/files-handler/files-handler.service';
import { fileIdToDownloadLink } from 'portal/chunks/files/files.functions';
import { FileWrapper } from 'portal/chunks/files/files.interfaces';
import { saveAs } from 'shared/utils/utils';

@Component({
  selector: 'app-files-list',
  template: `
      <span *ngFor="let file of filesHandler.filesArray$ | async"
            (click)="download(file)"
            [class.cursor-pointer]="!forEdit"
            class="badge badge-pill badge-secondary mr-3 my-1">
        <span class="mr-1" style="white-space: normal">{{ file.name }}</span>
        <i *ngIf="forEdit" class="fa fa-times-circle icon-button" (click)="filesHandler.removeFile(file)"></i>
      </span>
  `,
  styles: [`
    :host {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
  `]
})

export class FilesListComponent {
  @Input() filesHandler: FilesHandler;
  @Input() forEdit;

  download(file: FileWrapper) {
    if (this.forEdit) { return; }
    const url = fileIdToDownloadLink(file.guid);
    saveAs(url);
  }
}
