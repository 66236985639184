import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Output, TemplateRef } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { EmployeeModalService } from 'portal/modules/employee-modal/employee-modal.service';
import { DashboardService, UserInfo } from 'portal/pages/dashboard/dashboard.service';
import { EmployeesFetchService } from 'portal/pages/main/employees/employees.service';
import { PermissionsService } from 'portal/services/permissions.service';
import { Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ModalService } from 'shared/modules/modal/modal.service';
import { NavbarContentService } from 'shared/modules/navigation/navbar-content.service';
import {DownloadService} from 'shared/modules/download/download.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-portal-navbar',
    templateUrl: 'portal-navbar.component.html',
})
export class PortalNavbarComponent {
    userInfo$: Observable<UserInfo>;
    isAdmin: boolean;
    photo: Blob;
    modalRef: BsModalRef;
    @Output() toggleSidebar = new EventEmitter();
    navbarClass = '';

    constructor(
        private employeeModalService: EmployeeModalService,
        private dashboardService: DashboardService,
        private employeesFetchService: EmployeesFetchService,
        private modalService: ModalService,
        private downloadService: DownloadService,
        private http: HttpClient,
        permissionsService: PermissionsService,
        private navbar: NavbarContentService,
        private router: Router
    ) {
        this.userInfo$ = dashboardService.userInfo$;
        this.isAdmin = permissionsService.isAdmin();
        this.navbar.htmlClass$.subscribe(htmlClass => this.navbarClass = htmlClass);
    }

    changeAbout() {
        this.dashboardService.userInfo$
            .pipe(
                map(it => it.employee),
                switchMap((model) => {
                    const form: FormlyFieldConfig[] = [{
                        key: 'about',
                        type: 'textarea',
                        templateOptions: {
                            rows: 3,
                        },
                    }];
                    const title = 'О себе';
                    const onSubmit = (val) => this.employeesFetchService.setSelfAboutMe(val.about);
                    return this.modalService.updateForm({ model, form, title, onSubmit });
                }),
            ).subscribe();
    }


    async openPhotoModal(photo_file_guid: string, tpl: TemplateRef<any>) {
        this.photo = await this.downloadService
            .getFileContent(photo_file_guid)
            .pipe(catchError(e => this.http.get('./assets/images/no-image.png', { responseType: 'blob' })))
            .toPromise();
        this.modalRef = this.modalService.bsModalService.show(tpl);
    }

    savePhoto({ file, fileName }) {
        this.employeesFetchService
            .setOwnPhoto(file, fileName)
            .subscribe(({ guid, url }) => {
                this.dashboardService.userInfo.employee = {
                    ...this.dashboardService.userInfo.employee,
                    photo_file_guid: guid,
                    photo_url: url,
                };
                this.dashboardService.userInfo = { ...this.dashboardService.userInfo };
                this.modalRef.hide();
            });
    }

    handleLogoutLinkClick() {
        this.http.post('api/v2/auth/logout', {}).subscribe(() => this.router.navigateByUrl('/login'));
    }
}
