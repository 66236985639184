/* tslint:disable */
import { Component, ElementRef, ViewChild } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-input',
  template: `
      <input *ngIf="type !== 'number' else numberTmp" [type]="type" [formControl]="formControl" class="form-control"
             #inputElement
             [formlyAttributes]="field" [class.is-invalid]="showError">
      <ng-template #numberTmp>
          <input type="number" #inputElement [formControl]="formControl" class="form-control" [formlyAttributes]="field" [class.is-invalid]="showError">
      </ng-template>
  `,
  host: {
    '[class.d-inline-flex]': 'to.addonLeft || to.addonRight',
    '[class.custom-file]': 'to.addonLeft || to.addonRight',
  },
})
export class FormlyFieldInput extends FieldType {
  @ViewChild('inputElement') inputElement: ElementRef<HTMLElement>;
  get type() {
    return this.to.type || 'text';
  }

  ngAfterViewInit(): void {
    if (this.to.focusAfterRender) {
      requestAnimationFrame(() => this.inputElement.nativeElement.focus());
    }
  }
}
