import { Component, HostBinding, HostListener, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BookingRow, PlaceCol } from 'portal/pages/main/booking/booking.interfaces';
import { Point, PointsQuery, PointsStore } from 'portal/pages/main/place-booking/old-map/map/points/points.service';
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { ModalService } from 'shared/modules/modal/modal.service';
import { CellData } from 'shared/modules/table/table-config.model';

@Component({
  selector: 'app-booking-header-cell',
  template: `
    {{ d.name }}
    <i *ngIf="d.private" class="mr-1 fas fa-lock"></i>
    <i class="mr-1 fas fa-info-circle icon-button text-secondary" (click)="openInfo()"></i>
    <ng-template #bookingPlaceInfo>
        <div class="m-3">
            <div class="d-flex justify-content-between">
                <h3 class="mb-0">{{ d.name }}</h3>
                <i class="fa fa-times icon-button" (click)="hide()"></i>
            </div>
            <hr>
            <dl>
                <ng-container *ngIf="d.internal_phone && d.internal_phone.length">
                    <dt>Внутренний телефон:</dt>
                    <dd>{{ d.internal_phone }}</dd>
                </ng-container>
                <ng-container *ngIf="d.capacity && d.capacity.length">
                    <dt>Вместительность:</dt>
                    <dd>{{ d.capacity }}</dd>
                </ng-container>
            </dl>
            <hr>
            <div class="d-flex justify-content-between">
                <a class="btn btn-primary"
                   *ngIf="mapPlace$ | async as mapPlace"
                   (click)="hide()"
                   [routerLink]="['/map', mapPlace.floor]"
                   [queryParams]="{ search: mapPlace.title }">
                    <i class="fa fa-search"></i>
                    Показать на карте
                </a>
                <button class="btn btn-danger" (click)="hide()">
                    Закрыть
                </button>
            </div>
        </div>
    </ng-template>
  `,
})
export class BookingHeaderCellComponent {
  @ViewChild('bookingPlaceInfo', { static: true }) bookingPlaceInfoTpl: TemplateRef<any>;

  d: PlaceCol;
  mapPlace$: Observable<Point>;
  private modalRef: BsModalRef;

  constructor(
    data: CellData<BookingRow, PlaceCol>,
    private modal: ModalService,
    private pointsStore: PointsStore,
    private pointsQuery: PointsQuery,
  ) {
    this.d = data.column.data;

    this.mapPlace$ = this.pointsQuery
      .selectAll({ filterBy: it => it.type === 'title' && it.title === this.d.name })
      .pipe(pluck(0));
  }

  async openInfo() {
    if (!this.pointsStore.initiated) {
      await this.pointsStore.initStoreWithPersistentData();
    }

    this.modalRef = this.modal.bsModalService.show(this.bookingPlaceInfoTpl);
  }

  hide() {
    this.modalRef.hide();
  }
}
