import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import { PaginationService } from 'shared/modules/table/paginator/pagination.service';

@Component({
    selector: 'app-table-paginator',
    template: `
        <ng-content></ng-content>
        <ul *ngIf="count && lastPage!==1" class="pagination">
            <li class="page-item" [class.disabled]="activePage <= 1" (click)="setPage(1)">
                <a class="page-link" style="cursor: pointer">&laquo;</a>
            </li>
            <li class="page-item" *ngIf="activePage > 4 && activePage + 1 > lastPage" (click)="setPage(activePage - 4)">
                <a class="page-link" style="cursor: pointer">{{activePage - 4}}</a>
            </li>
            <li class="page-item" *ngIf="activePage > 3 && activePage + 2 > lastPage" (click)="setPage(activePage - 3)">
                <a class="page-link" style="cursor: pointer">{{activePage - 3}}</a>
            </li>
            <li class="page-item" *ngIf="activePage > 2" (click)="setPage(activePage - 2)">
                <a class="page-link" style="cursor: pointer">{{activePage - 2}}</a>
            </li>
            <li class="page-item" *ngIf="activePage > 1" (click)="setPage(activePage - 1)">
                <a class="page-link" style="cursor: pointer">{{activePage - 1}}</a>
            </li>
            <li class="page-item active">
                <a class="page-link" style="cursor: pointer">{{activePage}}</a>
            </li>
            <li class="page-item" *ngIf="activePage + 1 <= lastPage" (click)="setPage(activePage + 1)">
                <a class="page-link" style="cursor: pointer">{{activePage + 1}}</a>
            </li>
            <li class="page-item" *ngIf="activePage + 2 <= lastPage" (click)="setPage(activePage + 2)">
                <a class="page-link" style="cursor: pointer">{{activePage + 2}}</a>
            </li>
            <li class="page-item" *ngIf="activePage + 3 <= lastPage && activePage < 3" (click)="setPage(activePage + 3)">
                <a class="page-link" style="cursor: pointer">{{activePage + 3}}</a>
            </li>
            <li class="page-item" *ngIf="activePage + 4 <= lastPage && activePage < 2" (click)="setPage(activePage + 4)">
                <a class="page-link" style="cursor: pointer">{{activePage + 4}}</a>
            </li>
            <li class="page-item" [class.disabled]="activePage >= lastPage" (click)="setPage(lastPage)">
                <a class="page-link" style="cursor: pointer">&raquo;</a>
            </li>
        </ul>
        <span *ngIf="count && count > 5" class="count-info">
          {{
            ((activePage - 1) * rowsOnPage + 1) +
            (
                    (activePage - 1) * rowsOnPage + 1 === count
                            ? ''
                            : '-' +
                            (
                                    activePage === lastPage ?
                                            count :
                                            (activePage) * rowsOnPage
                            )
            )
            + ' из ' + count
            }}
        </span>
        <ul *ngIf="count && rowsOnPageSet[0] < count" class="pagination">
            <li class="page-item"
                *ngFor="let rows of rowsOnPageSet"
                [class.active]="rowsOnPage === rows"
                (click)="setRowsOnPage(rows)">

                <a class="page-link" style="cursor: pointer">{{rows}}</a>
            </li>
        </ul>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginatorComponent implements OnChanges, AfterViewInit {
    @Input() count;

    private _offset;
    get offset() {
        return this._offset;
    }

    @Input() set offset(value) {
        this._offset = value;
        this.offsetChange.emit(value);
    }

    @Output() offsetChange = new EventEmitter<number>();

    private _rowsOnPage = 30;
    get rowsOnPage(): number {
        return this._rowsOnPage;
    }

    @Input() set rowsOnPage(value: number) {
        this._rowsOnPage = value;
        this.rowsOnPageChange.emit(value);
    }

    @Output() rowsOnPageChange = new EventEmitter<number>();

    @Input() rowsOnPageSet = [30, 50, 100];

    lastPage = 1;
    activePage;

    constructor(
        private cdRef: ChangeDetectorRef,
        private paginationService: PaginationService,
    ) {
    }

    ngOnChanges(changes: SimpleChanges): any {
        this.calculateLastPage();
        this.calculateActivePage();
    }

    setRowsOnPage(rows) {
        const countSkippedEntities = this.activePage === this.lastPage
            ? this.count
            : this.rowsOnPage * this.activePage;
        this.rowsOnPage = rows;
        this.activePage = Math.ceil(countSkippedEntities / this._rowsOnPage);
        this.calculateLastPage();
        this.calculateOffset();
        this.cdRef.markForCheck();
    }

    setPage(page) {
        this.activePage = page;
        this.calculateOffset();
        this.cdRef.markForCheck();
    }

    private calculateLastPage() {
        this.lastPage = Math.ceil(this.count / this.rowsOnPage);
    }

    private calculateOffset() {
        this.offset = this.rowsOnPage * (this.activePage - 1);
    }

    private calculateActivePage() {
        this.activePage = (this.offset / this.rowsOnPage) + 1;
    }

    ngAfterViewInit(): void {
        this.paginationService.resetActivePage$.subscribe((reset) => {
            this.offsetChange.emit(0);
        });
    }
}
