import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ModalModule as BsModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ImageModalComponent } from 'shared/modules/modal/image-modal/image-modal.component';
import { InfoModalComponent } from 'shared/modules/modal/info-modal/info-modal.component';
import { FormPartsModule } from '../forms/forms.module';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { DefaultModalComponent } from './default-modal/default-modal.component';
import { FormModalComponent } from './form-modal/form-modal.component';
import { ModalService } from './modal.service';
import { WizardModalComponent } from './wizard-modal/wizard-modal.component';
import { ActionsModalComponent } from './actions-modal/actions-modal.component';


@NgModule({
  imports: [
    CommonModule,
    BsModalModule.forRoot(),
    FormPartsModule,
    TabsModule.forRoot(),
  ],
    exports: [DefaultModalComponent, FormPartsModule, WizardModalComponent, FormModalComponent],
  declarations: [
    DefaultModalComponent,
    InfoModalComponent,
    ConfirmModalComponent,
    FormModalComponent,
    WizardModalComponent,
    ImageModalComponent,
    ActionsModalComponent,
  ],
  entryComponents: [
    InfoModalComponent,
    ConfirmModalComponent,
    FormModalComponent,
    WizardModalComponent,
    ImageModalComponent,
  ],
  providers: [ModalService],
})
export class ModalModule {}
