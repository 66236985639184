import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { EnumsService } from 'portal/services/enums.service';
import { combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { filter, map, startWith, switchMap } from 'rxjs/operators';
import { ModalService } from 'shared/modules/modal/modal.service';
import { toPHPDate } from 'shared/utils/form.utils';
import { Utils } from 'shared/utils/utils';
import { DashboardService, UserInfo } from 'portal/pages/dashboard/dashboard.service';

export const maybeShowEmployeeHeadOnTopOfTheForm = (form: FormlyFieldConfig[], userInfo: UserInfo) => {
  const head = {
    template: `<div class="d-flex justify-content-between mb-3">
                  <span>Руководитель:</span>
                  <span>${userInfo.employee ? userInfo.employee.head_employee_display_name : ''}</span>
               </div>`,
  };
  if (userInfo.employee && userInfo.employee.head_employee_display_name) {
    form.unshift(head as any);
  }
  return form;
};

const threshold = {
  minDate$: new Subject(),
  sub: new Subscription(),
};

@Injectable()
export class VacationApplicationModal implements CanActivate {
  private form$: Observable<FormlyFieldConfig[]> = combineLatest([this.enumsService.getVacationTypes(), this.dashboardService.userInfo$])
    .pipe(map(([vacationTypes, userInfo]) => maybeShowEmployeeHeadOnTopOfTheForm([
      {
        key: 'vacation_type_id',
        type: 'select',
        hooks: {
          onInit: field => {
            const { value, valueChanges } = field.formControl;
            threshold.sub = valueChanges
              .pipe(
                startWith(value),
                filter(it => it),
                map(id => vacationTypes.find(it => it.value === id).threshold_days),
                map(days => Utils.fromToday(days)),
              ).subscribe(threshold.minDate$);
          },
          onDestroy: () => threshold.sub.unsubscribe(),
        },
        templateOptions: {
          label: 'Вид отпуска',
          options: vacationTypes,
          required: true,
        },
      },
      {
        key: 'date_from',
        type: 'daterange',
        hideExpression: '!model.vacation_type_id',
        templateOptions: {
          label: 'Даты',
          required: true,
          minDate: threshold.minDate$,
        },
      },
      {
        key: 'comment',
        type: 'textarea',
        templateOptions: {
          label: 'Комментарий',
          rows: 3,
        },
      },
    ], userInfo)));

  constructor(
    private router: Router,
    private modal: ModalService,
    private enumsService: EnumsService,
    private dashboardService: DashboardService,
    private http: HttpClient,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (window.location.pathname === '/vacation') {
      this.router.navigateByUrl('/');
    }
    this.form$.pipe(
      switchMap(form => this.modal.createForm({
        title: 'Заявка на отпуск',
        buttonTitle: 'Отправить',
        form,
        onSubmit: (val: any) => {
          const model = { ...val, ...{ date_from: toPHPDate(val.date_from[0]), date_to: toPHPDate(val.date_from[1]) } };
          return this.http.post('/api/v2/vacation/application', model);
        },
      })),
    ).subscribe();
    return false;
  }
}
