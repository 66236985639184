import { Component, EventEmitter, Output } from '@angular/core';
import { NavbarContentService } from '../navbar-content.service';

@Component({
    selector: 'app-navbar',
    templateUrl: 'navbar.component.html',
})
export class NavbarComponent {
    @Output() toggleSidebar = new EventEmitter();


    constructor(
        public navbarContent: NavbarContentService,
    ) {
    }

    toggle() {
        this.toggleSidebar.emit();
        this.navbarContent.toggle();
    }
}
